import axios from "axios";
import { GetCookie, check_vat } from "../../provider/common";

export async function GetCartCnt() {
  const token = await GetCookie("token");
  try {
    //장바구니 쿠키값이 있는지 체크하기
    const res = await axios.get(process.env.PANDA_API_URL + "/api/cart/kind", {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ` + token,
      },
    });
    if (res.data.status === 200) {
      //setCartCnt(res.data.data.cart_count);
      return res.data.data.cart_count;
    }
  } catch (e) {
    return 0;
  }
}

export async function toXlsx(ct_ids, od_id) {
  const XLSX = require("xlsx-js-style");

  //엑셀 다운로드 전 데이터 불러오기
  let cartids = ct_ids.join(",");
  let contents = [];

  const list = await getStatementList(cartids, od_id);
  if (ct_ids) {
    const basecontent = await list.lists.map((el) => {
      return {
        부품번호: el.cart_it_name,
        제조사: el.it_brand,
        유통사: el.cart_it_maker,
        주문수량: el.ct_qty,
        단가: el.ct_unit_price,
        공급가액: el.ct_price,
      };
    });
    contents = basecontent;
    //계 추가
    contents.push({
      부품번호: "계",
      제조사: "",
      유통사: "",
      주문수량: "",
      단가: "",
      공급가액: list.cart_total_price,
    });
    //부가세포함
    contents.push({
      부품번호: "부가세",
      제조사: "",
      유통사: "",
      주문수량: "",
      단가: "",
      공급가액: check_vat(Number(list.cart_total_price)),
    });
    //배송비 추가
    contents.push({
      부품번호: "배송비",
      제조사: "(부가세포함)",
      유통사: "",
      주문수량: "",
      단가: "",
      공급가액: list.delivery_fee,
    });
    //릴 비용 추가
    contents.push({
      부품번호: "Reel",
      제조사: "(부가세포함)",
      유통사: "",
      주문수량: "",
      단가: "",
      공급가액: list.reel_fee,
    });
    //총 계 추가
    contents.push({
      부품번호: "합계",
      제조사: "",
      유통사: "",
      주문수량: "",
      단가: "",
      공급가액: list.total_price,
    });
  } else {
    contents.push(
      list.items.map((el) => {
        return {
          A: el.cart_it_name,
          B: el.it_brand,
          C: el.cart_it_maker,
          D: el.ct_qty,
          E: "₩" + el.ct_unit_price,
          F: "₩" + el.ct_price,
        };
      })
    );
    //계 추가
    contents.push({
      cart_it_name: "계",
      it_brand: "",
      cart_it_maker: "",
      ct_qty: "",
      ct_unit_price: "",
      ct_price: list.od_cart_price,
    });
    //부가세포함
    contents.push({
      cart_it_name: "부가세",
      it_brand: "",
      cart_it_maker: "",
      ct_qty: "",
      ct_unit_price: "",
      ct_price: check_vat(Number(list.cart_total_price)),
    });
    //배송비 추가
    contents.push({
      cart_it_name: "배송비",
      it_brand: "(부가세포함)",
      cart_it_maker: "",
      ct_qty: "",
      ct_unit_price: "",
      ct_price: list.delivery_fee,
    });
    //릴 비용 추가
    contents.push({
      cart_it_name: "Reel",
      it_brand: "(부가세포함)",
      cart_it_maker: "",
      ct_qty: "",
      ct_unit_price: "",
      ct_price: list.reel_fee,
    });
    //총 계 추가
    contents.push({
      부품번호: "합계",
      제조사: "",
      유통사: "",
      주문수량: "",
      단가: "",
      공급가액:
        Number(list.od_receipt_price) +
        Number(list.od_misu) -
        Number(list.od_cancel_price) -
        Number(list.od_receipt_point),
    });
  }
  const rows = contents.length;
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(contents, {
    header: ["부품번호", "제조사", "유통사", "주문수량", "단가", "공급가액"],
  });
  //헤더 스타일 시트 적용
  const headerBg = "f1f1f1";
  const headerSz = 14;
  ws["A1"].s = {
    fill: {
      patternType: "solid",
      bgColor: { rgb: headerBg },
      fgColor: { rgb: headerBg },
    },
    // set the style for target cell
    font: {
      sz: headerSz,
      bold: true,
    },
    alignment: { horizontal: "center", vertical: "" },
    border: { bottom: {} },
  };
  ws["B1"].s = {
    fill: {
      patternType: "solid",
      bgColor: { rgb: headerBg },
      fgColor: { rgb: headerBg },
    },
    // set the style for target cell
    font: {
      sz: headerSz,
      bold: true,
    },
    alignment: { horizontal: "center", vertical: "" },
  };
  ws["C1"].s = {
    fill: {
      patternType: "solid",
      bgColor: { rgb: headerBg },
      fgColor: { rgb: headerBg },
    },
    // set the style for target cell
    font: {
      sz: headerSz,
      bold: true,
    },

    alignment: { horizontal: "center", vertical: "" },
  };
  ws["D1"].s = {
    fill: {
      patternType: "solid",
      bgColor: { rgb: headerBg },
      fgColor: { rgb: headerBg },
    },
    // set the style for target cell
    font: {
      sz: headerSz,
      bold: true,
    },
    alignment: { horizontal: "center", vertical: "" },
  };
  ws["E1"].s = {
    fill: {
      patternType: "solid",
      fgColor: { rgb: headerBg },
    },
    // set the style for target cell
    font: {
      sz: headerSz,
      bold: true,
    },
    alignment: { horizontal: "center", vertical: "" },
  };
  ws["F1"].s = {
    fill: {
      patternType: "solid",
      bgColor: { rgb: headerBg },
      fgColor: { rgb: headerBg },
    },
    // set the style for target cell
    font: {
      sz: headerSz,
      bold: true,
    },
    alignment: { horizontal: "center", vertical: "" },
  };

  ws["!cols"] = [
    { wpx: 180 }, // A열
    { wpx: 180 }, // B열
    { wpx: 100 }, // C열
    { wpx: 100 }, // D열
    { wpx: 100 }, // C열
    { wpx: 100 }, // D열
  ];
  //높이 세팅
  let hpts = [{ hpt: 22 }];
  let i = 0;
  for (i = 1; i < rows + 1; i++) {
    hpts.push({ hpt: 20 });
  }
  ws["!rows"] = hpts; //height for row 2

  //폰트 죽이기 적용 //부가세 글자 표시
  ws["B" + String(rows - 1)].s = {
    // set the style for target cell
    font: {
      sz: 11,
      color: { rgb: "cccccc" },
    },
    alignment: { horizontal: "center", vertical: "center" },
  };
  ws["B" + String(rows)].s = {
    // set the style for target cell
    font: {
      sz: 11,
      color: { rgb: "cccccc" },
    },
    alignment: { horizontal: "center", vertical: "center" },
  };

  XLSX.utils.book_append_sheet(wb, ws, "sheet1"); // add worksheet to workbook
  XLSX.writeFile(wb, "거래내역.xlsx"); // write workbook
}

export async function getStatementList(ct_ids, od_id) {
  const token = await GetCookie("token");
  if (ct_ids) {
    const res = await axios.get(
      process.env.PANDA_API_URL + `/api/cart?type=id&ct_ids=${ct_ids}`,
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ` + token,
        },
      }
    );

    if (res.data.status === 200) {
      return res.data.data;
    }
  } else {
    const res = await axios.get(
      process.env.MASTER_URL + `/api/v2/order/${od_id}`,
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ` + token,
        },
      }
    );
    return res.data.data;
  }
}

// 엑셀 다운로드
export async function excelDown(type, ids) {
  let ExcelJS = require("exceljs");
  let workbook = new ExcelJS.Workbook();
  let worksheet = workbook.addWorksheet();

  // 장바구니에서 불러올때
  if (type === "cart") {
    var ct_ids = ids;
  } else {
    var od_id = ids;
  }

  try {
    let data = await getStatementList(ct_ids, od_id);

    // 엑셀에 맞는 형태로 데이터로 가공 함수
    function makeRawData(type, arr) {
      let rawData = [];
      for (let i of arr) {
        let elArr = [];
        if (type === "cart") {
          elArr.push(i["cart_it_name"]);
          elArr.push(i["it_brand"]);
          elArr.push(i["cart_it_maker"]);
        } else {
          elArr.push(i["it_name"]);
          elArr.push(i["it_brand"]);
          elArr.push(i["it_maker"]);
        }
        elArr.push(Number(i["ct_qty"]).toLocaleString());
        elArr.push(`₩ ${Number(i["ct_unit_price"]).toLocaleString()}`);
        elArr.push(`₩ ${Number(i["ct_price"]).toLocaleString()}`);
        rawData.push(elArr);
      }

      return rawData;
    }
    if (type === "cart") {
      var rawData = makeRawData(type, data.lists);
    } else {
      var rawData = makeRawData(type, data.items);
    }
    // 데이터를 table에 넣기
    worksheet.addTable({
      name: "MyTable",
      ref: "A1",
      headerRow: true,
      style: {
        theme: "TableStyleMedium2",
      },
      columns: [
        { name: "품목" },
        { name: "브랜드" },
        { name: "구매처" },
        { name: "수량" },
        { name: "단가" },
        { name: "공급가액" },
      ],
      rows: [...rawData],
    });
    let table = worksheet.getTable("MyTable");

    // Reel 비용
    if (type === "cart") {
      var reel = data.reel_fee;
    } else {
      var reel = data.items.reduce((acc, cur) => {
        if (cur.ct_reel_fee) {
          return acc + cur.ct_reel_fee;
        } else {
          return 0;
        }
      }, 0);
    }
    // 계( 공급가액 합 )
    if (type === "cart") {
      var cartTotal = data.cart_total_price;
    } else {
      var cartTotal = data.od_cart_price - reel;
    }
    table.addRow([
      "계",
      "",
      "",
      "",
      "",
      `₩ ${Number(cartTotal).toLocaleString()}`,
    ]);
    // 부가세
    const vat = check_vat(cartTotal);
    table.addRow([
      "부가세",
      "",
      "",
      "",
      "",
      `₩ ${Number(vat).toLocaleString()}`,
    ]);

    // 배송비
    if (type === "cart") {
      const shipping = data.delivery_fee;
      table.addRow([
        "배송비",
        "",
        "",
        "",
        "",
        `₩ ${Number(shipping).toLocaleString()}`,
      ]);
    }

    table.addRow([
      "REEL 비용",
      "",
      "",
      "",
      "",
      `₩ ${Number(reel).toLocaleString()}`,
    ]);
    // 합계
    if (type === "cart") {
      var total = data.total_price;
    } else {
      var total =
        Number(data.od_receipt_price) +
        Number(data.od_misu) -
        Number(data.od_cancel_price) -
        Number(data.od_receipt_point);
    }
    table.addRow([
      "합계",
      "",
      "",
      "",
      "",
      `₩ ${Number(total).toLocaleString()}`,
    ]);

    // 테이블 저장
    table.commit();

    // 보더 스타일 주기
    const borderStyles = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };

    // 범위설정
    worksheet.autoFilter = table.table.autoFilter;
    worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
      row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
        cell.border = borderStyles;
      });
    });

    // header 가운데 맞춤
    worksheet.getCell("A1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell("B1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell("C1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell("D1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell("E1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell("F1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // width 조절
    const wName = worksheet.getColumn(1);
    const wBrand = worksheet.getColumn(2);
    const wMaker = worksheet.getColumn(3);
    const wQty = worksheet.getColumn(4);
    const wUnitPrice = worksheet.getColumn(5);
    const wPrice = worksheet.getColumn(6);
    wName.width = 25;
    wBrand.width = 25;
    wMaker.width = 15;
    wQty.width = 5;
    wUnitPrice.width = 10;
    wPrice.width = 20;

    // 엑셀 다운로드

    workbook.xlsx
      .writeBuffer()
      .then((data) => {
        let blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let anchor = document.createElement("a");
        let url = URL.createObjectURL(blob);
        anchor.href = url;
        anchor.download = "pandaparts" + ".xlsx";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(url);
      })
      .catch((err) => console.log(err));
  } catch (e) {
    console.log(e);
  }
}
